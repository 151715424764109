import { Link } from "@app/site";
import {
  AppLogoIcon, BMessage, Heading
} from "@app/ui";
import {
  getUser, isLoggedIn, login,
  logout,
  onLoggedIn, onLogout, role,
  silentLogin, user
} from "@app/utils";
import { navigate } from "gatsby";
import React, { ReactNode, useContext, useEffect, useReducer } from "react";

import * as Sentry from "@sentry/gatsby";

type Session = {
  profile: user;
  current: role | null;
  tenants: role[];
  loginChecked: boolean;
  isOnboarding: boolean;
  logout: () => void;
  login: (token?: string) => Promise<any>;
  switchTenant: (id: string) => Promise<any>;
  startOnboarding: () => Promise<void>;
  endOnboarding: () => Promise<void>;
  isOnboardingSession: () => boolean;
  isLoggedIn: () => boolean;
};

export const initialSession: Session = {
  profile: null,
  current: null,
  tenants: [],
  loginChecked: false,
  isOnboarding: false,
  logout: () => {},
  login: async (token?: string) => {},
  switchTenant: async (id: string) => {},
  startOnboarding: async () => {},
  endOnboarding: async () => {},
  isOnboardingSession: () => false,
  isLoggedIn: () => false,
};

const SessionContext = React.createContext({
  store: initialSession,
  dispatch: undefined,
});

const { Provider } = SessionContext;

interface SessionProviderProps {
  children: ReactNode;
}

const reducer = (state: Session, action) => {
  switch (action.type) {
    case "setProfile":
      return {
        ...state,
        profile: { ...action.payload },
        tenants: [...action.payload?.getRoles()],
      };
    case "checkLogin":
      return { ...state, loginChecked: true };
    case "logout":
      return { ...initialSession };
    case "setCurrent":
      return {
        ...state,
        current: action.payload ? { ...action.payload } : null,
      };
    case "setTenants":
      return { ...state, tenants: [...action.payload] };
    case "setIsOnboarding":
      return { ...state, isOnboarding: action.payload };
    default:
      throw new Error();
  }
};

const useSession = () => {
  const { store, dispatch } = useContext(SessionContext);

  store.login = (token?: string) => {
    return (token ? login(token) : silentLogin()).then((user) => {
      dispatch({ type: "setProfile", payload: user });
      return user;
    });
    // .catch((error) => {
    //   console.error(error);
    //   navigate("/");
    // });
  };

  store.logout = () => {
    return logout();
  };

  store.switchTenant = async (id: string | null) => {
    if (!id) {
      dispatch({ type: "setCurrent", payload: null });
      return;
    }
    await getUser().then((user) => {
      if (id !== "default" && id !== store.current?.tenant?.id) {
        if (!user.roleAt(id)) {
          dispatch({ type: "setCurrent", payload: null });
          throw new Error("invalid tenant");
        }
        user.setDefaultTenant(id);
      }

      const role = user.resolveTenant();
      if (role) {
        dispatch({ type: "setCurrent", payload: role });
      }
    });
  };

  store.startOnboarding = async () => {
    dispatch({ type: "setIsOnboarding", payload: true });
    sessionStorage.setItem("_ob_sess", "true");
  };

  store.endOnboarding = async () => {
    sessionStorage.removeItem("_ob_sess");
    dispatch({ type: "setIsOnboarding", payload: false });
    // await pause(500);
  };

  store.isOnboardingSession = () => {
    const isOnboarding = !!sessionStorage.getItem("_ob_sess");

    // if (!!store.isOnboarding != !!isOnboarding) {
    // dispatch({ type: "setIsOnboarding", payload: !!isOnboarding });
    // }
    return isOnboarding;
  };

  store.isLoggedIn = () => {
    return isLoggedIn() && !!store.profile;
  };

  return store;
};

const SessionProvider = ({ children }: SessionProviderProps) => {
  const [state, dispatch] = useReducer(reducer, initialSession, undefined);

  useEffect(() => {
    let callback = () => dispatch({ type: "checkLogin" });
    if (window.location.pathname === "/app/auth/redirect") {
      dispatch({ type: "checkLogin" });
    } else {
      !state.isLoggedIn() &&
        silentLogin()
          // .then()
          .catch((error) => {
            console.error(error);
            dispatch({ type: "logout" });
            // navigate(
            //   `/login?p=${encodeURIComponent(window.location.pathname)}`
            // );
          })
          .finally(callback);
    }

    return function cleanup() {
      callback = () => {};
    };
  }, []);

  useEffect(() => {
    const onLoggedInCancel = onLoggedIn((user: user) => {
      // if (state.current) {
      //   // user no longer has access
      //   // then redirect to a "static" error page
      //   if (user.roleAt(state.current.tenant.id) == null) {
      //     const role = user.resolveTenant();
      //     user.setDefaultTenant(role ? role.tenant.id : null);
      //     dispatch({ type: "setCurrent", payload: role });
      //   }
      // }

      // // logic of handling the current tenant on LoggedIn event

      // if (!state.current) {
      //   const role = user.resolveTenant();

      //   // don't define the current tenant if it's an onboarding session
      //   // it must be set at the end of the OB
      //   // set the current tenant in case of a simple page reload
      //   // set the current tenant in case of a user that just create it's account and already has an access

      //   // TODO: keep thinking & polishing this logic...

      //   if (role) {
      //     // const isOBSession =
      //     // !user.defaultTenant() &&
      //     // role.role == "owner";
      //     // &&
      //     // user.getRoles().length === 1 &&
      //     // state.tenants.length == 0;
      //     // if (!isOBSession) {
      //     user.setDefaultTenant(role.tenant.id);
      //     dispatch({ type: "setCurrent", payload: role });
      //     // }
      //   }

      const role = user.resolveTenant();

      if (role) {
        user.setDefaultTenant(role.tenant.id);
        dispatch({ type: "setCurrent", payload: role });
      } else {
        dispatch({ type: "setCurrent", payload: null });
      }
      // set & update session user profile & list of tenants in each login refresh
      dispatch({ type: "setProfile", payload: user });

      // dispatch({ type: "setTenants", payload: user.getRoles() });
    });

    const onLogoutCancel = onLogout(() => {
      dispatch({ type: "logout" });
      // navigate("/login", { replace: true });
      if (window.location.pathname.startsWith("/app")) {
        navigate(`/login?p=${encodeURIComponent(window.location.pathname)}`, {
          replace: false,
        });
      }
    });

    // const onTenantChangedCancel = onTenantChanged(() => {
    //   // navigate(`/app/${tenantId}/`);
    // });

    return function cleanup() {
      onLoggedInCancel();
      onLogoutCancel();
      // onTenantChangedCancel();
    };
  }, []);

  useEffect(() => {
    if (!!state.profile) {
      Sentry.configureScope((scope) => {
        scope
          .setUser({
            id: state.profile.id,
          })
          .setTag("tenant", state?.current?.tenant?.id)
          .setTag("tenant_name", state?.current?.tenant?.name)
          .setTag("role", state?.current?.role);
      });
    }
  }, [state?.profile]);

  if (state.profile && state.profile.waitListed) {
    return (
      <BMessage
        message={
          <>
            <Heading size={"md"} as={"span"}>
              {/* {state?.profile ? `${state.profile.name()}, thanks` : "Thanks"}{" "} */}
              Thanks, for requesting early access to join <b>DeckedLink</b>
            </Heading>
            <br />
            <br />
            Once your access is granted, we will let you know
            {/* <br /> */}
            {state?.profile?.email ? (
              <>
                {/* <br /> */} via email to <b>{state?.profile?.email}</b>{" "}
              </>
            ) : null}
            <br /> <br /> <br />
            {/* <Spacer /> */}
            See you soon!
            <br /> <br />
            <Link to="/">Go back to home</Link>
          </>
        }
        icon={
          <>
            <AppLogoIcon boxSize={28} />
          </>
        }
      />
    );
  }
  return (
    <Provider
      value={{
        store: state,
        dispatch,
      }}
    >
      {/* {state.loginChecked ? children : <LoadingLayout />} */}
      {/* {state.loginChecked ? ( */}
      {state.profile || window.location.pathname === "/app/auth/redirect" ? (
        children
      ) : (
        <BMessage
          message={"loading session..."}
          icon={
            <>
              <AppLogoIcon boxSize={28} />
            </>
          }
        />
      )}
    </Provider>
  );
};

export { useSession, SessionProvider as Provider };
