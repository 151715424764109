import React, { useEffect, useState } from "react";

import { useSession } from "@app/context/Session";
import { BMessage, ErrorIcon, LoadingBlock, useToast } from "@app/ui";
import { navigate } from "gatsby-link";

export interface CallbackProps {
  path: string;
  location: Location;
}

const ERRORS_MAP = {
  default: "An error has occured. Please, retry again or contact support",

  unauthorized: "Missed authorization, you can't perform this action",

  save_credentials_failed:
    "Failed to save credentials. Please retry again or contact support",

  invalid_redirect_url: "Invalid redirect URL",

  "#id003":
    "Notion workspace already linked. Please use another one or different Notion user account.",
};

export const hasQueryError = ({ params }) => {
  params = new URLSearchParams(params);
  return params.has("error")
    ? ERRORS_MAP?.[params.get("error")] || ERRORS_MAP.default
    : null;
};
export const redirect = ({ params }) => {
  return navigate(`/app/auth/redirect${params}`);
};

export const Redirect = ({ path, location }: CallbackProps) => {
  const { login, switchTenant, startOnboarding } = useSession();

  const [error, setError] = useState(null);

  const { failure } = useToast();

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    // console.log("callback redirect debug params", location.search, params);

    if (params.has("error")) {
      const errMsg = ERRORS_MAP?.[params.get("error")] || ERRORS_MAP.default;
      setError(errMsg);
      failure(errMsg);
      return;
    }

    if (params.has("at")) {
      login(params.get("at"))
        .then(() => {
          params.has("p") && params.get("p")
            ? navigate(params.get("p"))
            : navigate("/app");
        })
        .catch((e) => {
          failure(ERRORS_MAP.default);
          console.error(e);
        });
    }

    if (params.has("tid")) {
      login()
        .then(() => switchTenant(params.get("tid").trim()))
        .then(() => {
          if (params.get("p").includes("onboarding?")) {
            startOnboarding().then(() => navigate(`/app/${params.get("p")}`));
            return;
          }
          navigate(`/app/${params.get("tid").trim()}/${params.get("p")}`);
        })
        .catch((err) => {
          console.error(err);
          failure("invalid workspace, or access denied");
          navigate("/app/select-workspace");
        });
    }
  }, []);

  return error ? (
    <BMessage
      message={error}
      icon={
        <>
          {" "}
          <ErrorIcon />{" "}
        </>

        // to do improve error page gives options // go to home, report....
      }
    />
  ) : (
    <BMessage
      message={"redirect..."}
      icon={
        <>
          <LoadingBlock />
        </>
      }
    />
  );
};
